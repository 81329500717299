@import 'vendor/youtube_modal';
@import 'partials/animate';
@import 'modules/variables';
@import 'partials/others';
@import 'partials/navbars';
@import 'partials/forms';
@import 'partials/slick';
@import 'partials/fancyboxgallery';
body
{
	font-family: $font-frank,sans-serif;
	background:#fff;
	
}
a:focus,a:active,a:hover
{
	outline:none;
	text-decoration: none;
	color:inherit;
}
h1,h2,h3
{
	
	font-family: $font-frank,sans-serif;

}
hr.small
{
	border-color:$theme-yellow;
	border-width: 3px;
	
}
.with_hr
{
	&:after
	{
		content:"";
		display:block;
		width:60px;
		height:5px;
		background-color: $theme-yellow;
		margin-top:10px;
		margin-bottom: 20px;
	}
}
.top-margin
{
	height:400px;
}




/////////////////////////////////////////


section#about
{
	padding:100px 0 60px;
	h5
	{
		font-family: $font-frank;
		max-width:600px;
		text-align: center;
		margin:0 auto;
		line-height: 25px;
		font-size: 18px;
		letter-spacing: 1.2px;
	}
}

////////////////////
/////////////////// PORTFOLIO
.cbp-item
{
	position:relative;
	&:hover
	{
		.portfolio_detail
		{
			display:block;
			opacity:1;		
			
		}
		.overlay
		{
			opacity:0.8;
		}

	}
	.overlay
	{
		background:$theme-yellow;
		position: 	absolute;	
		top:0;
		left:0;
		width:100%;
		height:100%;
		opacity:0;

	}
	.portfolio_detail
	{
		opacity:0;
		position:absolute;
		top:50%;
		left:0;		
		width:100%;				
		text-align:center;
		transform:translateY(-50%);
		a
		{
			color:#fff;
			font-size:3em;
		}
	}
}

///////////////
#image_gallery
{
	padding: 40px 0 80px 0;
}

///////////////////
@media screen and (min-width:992px)
{
	.only_in_mobile
	{
		display:none;
	}
	footer
	{
		color:rgba(#000,0.5);
		a
		{
			color:rgba(#000,0.9);
		}
		p.footer_left
		{
			float:left;
		}
		.footer_right
		{
			float:right;

		}
	}
}
footer
{	
	padding:20px 0 20px 0;
	text-align: center;
	border-top:2px solid $theme-yellow;
	p
	{
		margin-bottom: -1px;
	}
	
}
.btn-rashis
{
	background-color:#333;
	padding:15px 20px;
	color:#fff;
	&:hover
	{
		color:$theme-yellow;
		text-decoration: none;
	}
}
.btn-rashis2
{
	background-color:$theme-yellow;
	padding:15px 20px;
	color:#333;
	&:hover
	{
		color:black;
		text-decoration: none;
	}
}

.container-fluid
{
	padding-left:0;
	padding-right: 0;
}

///////////////////button
.button_container
{
	img
	{
		width:200px;
	}
}
@media screen and (max-width:600px)
{
	.button_container
	{
		img
		{
			width:120px;
		}
	}
	.padding-variation
	{
		padding:15px;
	}
}
#subbanner
{
	padding:70px 0;
	text-align: center;
	background:$theme-blue;
	color:#fff;
	font-weight: 700;
}

nav{ 
  overflow: hidden;
  -webkit-transition: 500ms all ease-in-out;
  -moz-transition: 500ms all ease-in-out;
  -ms-transition: 500ms all ease-in-out;
  -o-transition: 500ms all ease-in-out;
}
.menu-close {
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
}

///////////////
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video_container
{
	padding:30px;
  ul
  {
   list-style:none; 
   padding-left: 0px;
    li
    {
      width:16%;
      cursor:pointer;
      &:hover
      {
      	img
      	{
      		margin-top:-5px;
      	}
      }
    }
    img
    {
      width:100%;
      float:left;
      padding:0 5px;
      transition:all 0.5s ease;
    }
  }
}

@media screen and (max-width:900px)
{
	.video_container ul li
	{
		width:30%;
	}

}
@media screen and (max-width:600px)
{
	.video_container ul li
	{
		width:33%;
	}

}


/////////////css for image map

.zoom {
    border: 0;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
}

    .zoom p {
        position: absolute;
        top: 3px;
        right: 28px;
        color: #555;
        font: bold 13px/1 sans-serif;
    }

    /* magnifying glass icon */
    .zoom:after {
        content: '';
        display: block;
        width: 33px;
        height: 33px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.3;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAMAAABgOjJdAAAAOVBMVEUAAAD///8AAAAAAAAVFRUpKSk8PDxPT09gYGBxcXGCgoKSkpKwsLC+vr7MzMzZ2dnm5ubz8/P///+LgQr7AAAAE3RSTlMAAFSGiYyOkZSXmp2ipairrbCz61ViJgAAAJ9JREFUeF6tz70Og0AMA2CfgaPl987v/7AFlqgemqWWsn2OZJRSOPzKLcBUgKlwUuf6LYzUJqlVEwgydfXjumoiyKZzHMZTm4kgTXe7qpsI0h8xSSaC7M//TbuJILN0rIf0chHkrSfnGMLJtKzLq1/EhI2uD3HhZHfhpK0mnNgWI4kAUwGmAkwFmAowFWAqwFSAqQBTAaYCTAWIkuUf4gNsjQljXxt9tgAAAABJRU5ErkJggg==') /*icon.png*/;
    }

    .zoom img {
        display: block;
    }

        .zoom img::selection {
            background-color: transparent;
        }


div#imgShown, img, canvas {
    display: block;
    max-width: 100%;
    height: auto;
}
.video_container_vimeo
{
	max-width:850px;
	margin: 0 auto;
}

.modal
{
	top:20%;
}
.modal-body
{
	text-align: 	center;
	padding:10vh;
	h4
	{
		color:#000;
	}
	p
	{
		color:rgba(#000,0.5)
	}
}
.btn-secondary
{
	background: 	$theme-yellow	;
	color:#000;
	border-color:transparent;

}
.price_contain
{
  background:#fff;
  text-align:center;
  padding:20px;
  margin-top:20px;
  border-left:1px solid #f1f1f1;
  a
  {
    color:$theme_yellow;
    font-size:1.5em;
    line-height:0.9;
    display: block;
    padding:20px 0 25px 0;
    &:hover
    {
      text-decoration:none;
    }
  }
  p
  {
    font-size:0.9em;
    color:#333;
  }
  h5
  {
   
    span
    {
      color:rgba(#000,0.4);
      font-size:70%;
      text-transform: uppercase;
    }
  }
  .price_list
  {
        list-style:none;
    text-align:center;
    padding-left:0;
    li
    {
      display:inline-block;
      padding-left:10px;
      i
      {
        color:$theme-yellow;
      }
    }
  }
}
.price_amount
{
  background-color:#333;
  color:#fff;
  text-align:center;
  padding:10px;
}


.fa-phone
{
  transform:rotate(80deg);
}