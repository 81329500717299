.form-control
{
	border:none;
	border-bottom:1px solid $theme-yellow;
	margin-top: 0px;
	margin-bottom: 30px;
	background-color: transparent;
	&:focus
	{
		background-color: transparent;
		border-bottom:1px solid $theme-yellow;


	}
}
label
{
	margin-bottom: 0;
	color:rgba(#000,0.99);
	font-size:13px;
}
.form-row
{
	margin-bottom: 00px;
}
.westoak_button
{
	background-color:$theme-yellow;
	border:none;
	padding:10px 13px;
	font-size:14px;
	color:#fff;
	border-radius: 2px;
}