.property_block
{
	margin-bottom: 20px;
	img
	{
		width:100%;
	}
}
.property_details
{
	background-color:$theme_blue;
	padding:20px;
	position: relative;
	margin-bottom: 50px;
	img
	{
		display: block;
		width:40px;
		margin:0 auto;
	}
	h5
	{
		color:#fff;
		text-align: center;
		font-family: $font-frank;
		font-size: 15px;
		margin-top: 10px;
	}
	a
	{
		position: absolute;
		background:$theme_yellow;
		padding:5px 15px;
		color:#000;
		top:100%;
		right: 0;
		letter-spacing: 1px;
		font-weight: 500;
	}
}

.property_banner
{
	position:relative;
	.video_icon
	{
		position:absolute;
		top:5%;
		right:5%;		
		font-size:25px;		
		width:50px;
		text-align:center;
		height:50px;
		color:#fff;	
		animation-name:blink;
		animation-duration:3s;
		animation-iteration-count: infinite;	
	}	
	.property_name
	{
		position: absolute;
		top:100%;
		width: 100%;
		transform:translateY(-100%);
		h5
		{
			background:#fff;
			display: inline-block;
			padding:10px 15px;
			margin:0 auto;
			text-align: center;
			font-size:25px;
			color:$theme_blue;
			text-transform: uppercase;
			font-family: $font-frank;
			letter-spacing: 2px;
		}
	}
}
@keyframes blink {
    0%   {opacity:0.5;}  
    50% {opacity:1;}
    100% {opacity:0.5;}
}
hr.image
{
	background-image:url('../../img/hr_background.png') ;
	background-repeat: no-repeat;
	background-size: 60px ;
	border:0;
	background-position:center;	
	height:40px;
	
}

/////////////////////Features Section
.amenities
{
	background: $theme_blue;
	padding:80px;
	h4
	{
		color:#fff;
		font-size: 30px;
	}
}
.amenities_list
{
	width:100%;
	padding:0;
	li
	{
		width:49%;
		display:inline-block;
		color:#fff;
		margin-bottom: 10px;
		font-size:15px;
		svg
		{
			color:$theme_yellow;
		}
		&:before
		{
			content:"";
			background-image:url("../../img/check.png");

		}
	}
}
@media screen and (max-width:1600px)
{
	.amenities
	{
		padding:40px;

	}
	.amenities_list
	{
		width:100%;	
		li
		{
			width:100%;	
			font-size:14px;
		}
	}

}

@media screen and (max-width: 768px)
{
	.property_banner .property_name h5
	{
		font-size:18px;
	}
	.top-margin
	{
		height:350px!important;
	}
	.property_details
	{
		background-color:$theme_blue;
		padding:20px;
		img
		{
			display: block;
			width:30px;
			margin:0 auto;
		}
		h5
		{
			color:#fff;
			text-align: center;
			font-family: $font-frank;
			font-size: 13px;
			margin-top: 10px;
		}
	}
	
}

.neighbour
{
	margin-left: 0;
	padding-left: 0;
	li
	{
		display:block;
		list-style: none;
		border-bottom:1px dashed rgba($theme_yellow,0.5);
		padding:5px 0;
		span
		{
			float:right;
		}
	}
}

/////////////////////// responsive google map
.map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

///////////////// COntact form
#contact
{
	
}
.contact_form
{
	background:#e8e8e8;
	padding:60px 35px;
}
.roadsafety
{
	
	padding:30px;
	h3
	{
		color:#fff;
		text-align: center;
		margin-bottom: 15px;
	}
}
.personal_info
{	
	margin:0 auto;
	margin-bottom: 30px;
	p
	{
		margin-bottom:0px;
		line-height: 18px;

	}
	img
	{
		margin-bottom: 10px;
		margin-left:auto;
		margin-right:auto;
	}
	.name
	{
		color:$theme_yellow;
		margin-bottom: 10px;		
		font-size: 1.2em;
	}
	.profession
	{
		color:rgba(#fff,0.5);
	}
	.company
	{
		color:#fff;
	}
	hr
	{
		border-color:$theme_yellow;
	}
}
.contact_list
{
	color:#1f1f1f;
	font-size:0.95em;
	li i
	{
		color:$theme_yellow;
	}
	a
	{
		color:#1f1f1f;
		&:hover
		{
			color:$theme_yellow;
		}
	}
}

#map{
	width:100%;
	height:0;
	padding:32%;
}

//////////rashis 
.rashis_container
{
	max-width: 1800px;
	margin:0 auto;
}
.rashis-container
{
	max-width:1800px;
	margin:0 auto;
	padding-left:15px;
	padding-right:15px;
	padding:200px 0 200px 0;
	background-size:cover;
	background-position:center center;
	.banner_contents
	{
		text-align:center;
		h4
		{
			font-size:35px;
			color:#fff;			
			display:block;
			max-width:800px;
			margin:0 auto;
			text-shadow:2px 3px 5px rgba(#000,0.1);
			letter-spacing:2px;
			background:rgba(#000,0.1);			
			padding:30px 30px;
			line-height:1.2;
			background:$theme-blue;
			text-transform: uppercase;
			font-weight: 700;
		}
		h5
		{
			color:#fff;
			background:$theme-yellow;
			display: inline-block;
			max-width: 800px;
			padding:20px 30px;
			font-size:18px;
		}
	}
}

.img-border
{
	border:5px solid $theme_yellow;
}

.section_padding
{
	padding:90px 0 0 0;
}

////////////////buttons
.custom_btn
{
	background:$theme_yellow;
	color:#fff;
	padding:10px 30px;
	display:inline-block;
	border-radius:25px;
	font-weight:600;
	letter-spacing:1px;

}

@media screen and (max-width:900px)
{
	.section_padding
	{
		padding:20px 0 0 0;
	}
	.rashis-container
	{
		padding:50px 0;
		.banner_contents
		{
			padding:0 30px;
			h4
			{
				font-size:25px;
			}
		}
	}
}


///////////////////////////////// Westend Unit Block
.westend_unit
{
	padding:15px;
	.westend_unit_description
	{
		background-color:$theme_blue;
		color:#fff;
		padding:20px;
		text-align: center;
		img
		{
			width:35%;
			display:block;
			margin:0 auto;
			
		}
		.westend_unit_numbers
		{
			font-size:15px;
		}
		h5
		{
			span
			{
				font-size:80%;				
				color:rgba(#fff,0.3);
			}
		}
		.links_footer
		{
			text-align: center;
			display:block;
			margin:0;
			padding-left:15px;
			color:#fff;
			padding:10px 0;
			transition:all 0.5s ease;
			border-bottom:1px solid transparent;
			border-radius:5px;
			&:hover
			{
				background:rgba(#000,0.1);
				border-bottom:1px solid $theme_yellow;
			}
			
		}
	}
}
img.svg
{
	width:35px!important;
	display:inline-block!important;
	margin-top:-8px!important;
	padding-right:10px;
}
@media screen and (max-width:574px)
{
	
	.westend_unit
	{	
		padding:5px 0px;
	}
}
@media screen and (max-width:1516px)
{
	img.svg
	{
		width:35px!important;
		display:block!important;
		margin-top:-8px!important;
		padding-right:10px;
	}
}
img.icons
{
	margin-bottom:10px !important;
}
#schedule
{
	background-color:$theme_yellow;
	padding:80px 0 300px 0;
	color:#fff;

	text-align:center;
	h5
	{
		color:$theme_blue;
	}
	h4
	{
		line-height:1.3;
		font-weight:700;
		letter-spacing:1px;
	}
}

#team
{
	padding:50px 0;
	background:rgba(#000,0.1);
}

.team_block
{
	background:#fff;
}
.team
{
	text-align:center;	
	padding:20px 20px 20px ;
	margin-bottom:20px;
	background:#fff;
	margin-bottom:15px;
	border-radius:2px;
	height: 100%;
	border:3px solid rgba(#000,0.1);
	border-bottom:1px solid rgba(#000,0.1);
	&:last-child
	{
		border-right:none;
	}
	a
	{
		background-color:$theme_yellow;
		color:#fff;
		padding:10px;
	}
	img
	{
		
		width:150px!important;
		margin:0 auto;
		display:block;
		border:5px solid $theme_yellow;
		margin-bottom:15px;
	}
	.team_name
	{
		color:$theme-blue;
		font-size:18px;
		margin-bottom:2px;
		font-weight: 700;
	}
	h5
	{
		color:rgba(#000,0.7);
		font-size:17px;
	}
}
.shorttail
{
	width:50px;
	border-top:3px solid $theme_yellow;

}

h5.heading
{
	color:$theme-blue;
	font-weight: 700;
	font-size:22px;
	margin-bottom:15px;
}

.vision
{
	background:$theme-yellow;
	padding:20px 30px;
	padding-left:0;
	color:#fff;	
	h4
	{
		font-weight: 700;
		margin-bottom: 25px;
	}
	h5
	{
		font-weight: 700;
		color:$theme-blue;
	}
	hr
	{
		width:80px;
		margin-left:0;
	}
}

.objectives
{
	background-color: $theme-blue;
	padding:20px 30px;
	color:#fff;
	h4
	{
		font-weight: 700;
		margin-bottom: 25px;
	}
	ol
	{
		padding-left:15px;
		li
		{
			margin-bottom: 10px;
		}
	}
}


.team_heading
{
	color:$theme-blue;
	font-weight: 700;
	text-align: center;
	margin-bottom: 25px;
}


article
{
	padding:50px 0;
	h4
	{
		font-weight: 700;
		color:$theme-blue;
	}
	img
	{
		margin-bottom:15px;
		margin-top:10px;
	}
	.box
	{
		background:$theme-blue;
		padding:20px;
		color:#fff;
		h4
		{
			color:#fff;
		}
	}
	h5
	{
		background:$theme-yellow;
		padding:10px 15px;
		font-weight: 700;
		margin-top:20px;
		color:#fff;
		font-size:16px;
	}
	h3
	{
		background:$theme-yellow;
		display: inline-block;
		color:#fff;
		padding:10px 15px;
		font-size:21px;
		text-transform: uppercase;
	}
	ul
	{
		padding-left:20px;
	}
}
.img-responsive
{
	width:100%;
}