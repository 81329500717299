$theme_yellow:#f68620;
$theme_blue:#aa1f23;

/////////////// FONTS
@font-face {
    font-family: 'avenir'; /*a name to be used later*/
    src: url('fonts/AVENIRNEXTLTPRO-REGULAR_0.OTF'); /*URL to font*/
}

@font-face {
    font-family: 'gilroy'; /*a name to be used later*/
    src: url('fonts/Gilroy-ExtraBold.OTF'); /*URL to font*/
}
$font-gilroy:'gilroy';
$font-karla:'nunito', sans-serif;
$font-frank:'avenir', sans-serif;
$font-robo-con:'Roboto Condensed', sans-serif;

///////////////
.bg-dark
{
	background-color:#fff;
}