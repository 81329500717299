.nav-item
{
	a
	{
		text-transform: none;
		font-weight: 300;
	}

}
.nav-container
{
	display:flex;
	align-items:center;
	justify-content:space-between;
	margin-right:auto;
	margin-left:auto;
	padding-right:15px;
	padding-left:15px;
	width:100%;
	flex-wrap:wrap;
}
@media screen and (max-width:992px)
{
	.nav-container
	{
		padding:0;
	}
}
@media screen and (min-width:992px)
{
	.add_more
	{
		padding-left:15px;
		padding-right:15px;
		padding-bottom:30px;
	}
	.mobile_show
	{
		display:none;
	}
	.btn-nav
	{
		margin-left:0.9rem;
		padding-left:1.5rem;
		padding-right:1.5rem;
	}
	.navbar-expand-lg .navbar-nav .nav-link
	{
		padding-left:0.6rem;
		padding-right:0.6rem;
	}
	.nav-container
	{
		max-width:1700px;
		flex-wrap:nowrap;
	}
}


.navbar-light .navbar-toggler
{
	color:$theme_yellow;
	border-color:transparent!important;
	padding:0;
}
.navbar
{
	padding-top: 15px;
	padding-bottom: 15px;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(#000,0.8);
    font-size: 0.92em;
    font-weight:600;
    &:hover
    {
    	color:$theme_yellow;
    }
}
.btn-outline-secondary
{
	border-color:$theme_yellow;
	border-width:2px;
	background-color:$theme_yellow;
	color:#fff!important;
	&:hover
	{
		background-color:$theme_blue;
		border-color:$theme_blue;
	}

}
.navbar-light .navbar-nav .nav-link .btn
{
	color:#fff;
}

///////////////////Banner
header
{
	background-size:cover;
	.header_overlay
	{
		background-color:rgba($theme_blue,0.87);
		position: relative;
		&:after
			{
				content:"";
				width:4px;
				height:120px;
				display: block;
				background:$theme_yellow;
				position:absolute;
				top:100%;
				left:50%;
				transform:translate(-50%,-50%);
				z-index:499;				
				
			}
	}
}
.bg-light
{
	background-color: #fff!important;
}

